@import "antd/dist/antd.less";
.localization-modal {
  position: absolute;
  z-index: 2000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;

  overflow: hidden;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // padding: 12px;
    text-align: center;
    // margin-bottom: 64px;
    &-title {
      position: absolute;
      z-index: 2;
      color: #eee;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 300px;
      margin-bottom: 32px;
      h1 {
        font-size: 24px;
        color: #fff;
        margin: 0;
      }
      p {
        font-size: 14px;
        color: #eee;
      }
    }
  }
  &-btn {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    overflow: hidden;
    flex-grow: 1;
    height: 100vh;
    transition: all 0.1s ease-in-out;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.1s ease-in-out;
    }
    span {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: #fff;
      @media (min-width: 900px) {
        font-size: 20px;
      }
      @media (min-width: 1200px) {
        font-size: 26px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition: 0.1s ease-in-out;
    }
    &:hover img {
      transform: scale(1.04);
    }
    &:hover::after {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  // .localization-modal-btn-rzeszow:hover {
  //   flex-grow: 2;
  // }
  // .localization-modal-btn-rzeszow:hover + .localization-modal-btn-krakow {
  //   flex-grow: 1;
  // }
  // .localization-modal-btn-krakow:hover {
  //   flex-grow: 2;
  // }
  // .localization-modal-btn-krakow:hover + .localization-modal-btn-rzeszow {
  //   flex-grow: 1;
  // }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;