.representatives {
  padding: 0 24px;
  margin: 24px 0;

  h2 {
    font-size: 16px;

    font-weight: 800;
  }
  h3 {
    font-size: 14px;
  }
  &-wrapper {
    display: grid;
    row-gap: 24px;
    grid-template-columns: 1fr;
  }
  &-single {
    ul {
      padding-left: 0;
      list-style: none;
    }
    p {
      margin: 0;
    }
  }
  @media (min-width: 768px) {
    &-wrapper {
      column-gap: 12px;
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (min-width: 992px) {
    margin: 64px 0;
    h2 {
      font-size: 24px;
    }
  }
  @media (min-width: 1100px) {
    &-wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (min-width: 1400px) {
    margin: 92px 0;
    & {
      padding: 0 64px;
      margin-bottom: 64px;
    }
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;