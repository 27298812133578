.full-page-loading {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;