.accessibility-wrapper {
  display: flex;
  align-items: flex-end;

  gap: 4px;
  button {
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 600;

    align-self: flex-end;
    vertical-align: bottom;
  }

  button:hover {
    text-decoration: underline;
  }
  button:nth-of-type(1) {
    font-size: 20px;
    line-height: 22px;
  }
  button:nth-of-type(2) {
    font-size: 16px;
    line-height: 19px;
  }
  button:nth-of-type(3) {
    font-size: 12px;
    line-height: 18px;
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    svg {
      font-size: 20px;
      width: 17px;
      height: 17px;
    }
  }
}
.accessibility-xlarge {
  &,
  a:not(.xlarge):not(.large):not(.normal),
  .ant-select-selection-item,
  span,
  li,
  p,
  h2,
  h3,
  h4,
  h5,
  label,
  .ant-select-item-option-content,
  .ant-tabs-tab-btn {
    font-size: 1.5rem !important;
  }
  .ant-select-arrow {
    height: 25px;
    width: 20px;
    margin-top: -10px;
  }
  .select-wrapper-item,
  .localization-select {
    min-width: 200px !important;
  }
  .ant-btn {
    min-height: 45px;
  }
  .news .ant-carousel .slick-slider .slick-list {
    min-height: 500px;
  }
}
.accessibility-large {
  &,
  a:not(.xlarge):not(.large):not(.normal),
  span,
  li,
  p,
  h2,
  h3,
  h4,
  h5,
  label,
  .ant-select-item-option-content,
  .ant-tabs-tab-btn {
    font-size: 1.25rem !important;
  }
  .select-wrapper-item,
  .localization-select {
    min-width: 200px !important;
  }
  .ant-btn {
    min-height: 50px;
  }
  .news .ant-carousel .slick-slider .slick-list {
    min-height: 350px;
  }
}
.accessibility-normal {
  &,
  a:not(.xlarge):not(.large):not(.normal),
  span,
  li,
  p,
  h2,
  h3,
  h4,
  h5,
  label,
  .ant-select-item-option-content,
  .ant-tabs-tab-btn {
    font-size: auto;
  }
  .select-wrapper-item,
  .localization-select {
    min-width: auto !important;
  }
  .ant-btn {
    min-height: auto;
  }
  .news .ant-carousel .slick-slider .slick-list {
    min-height: auto;
  }
}
.accessibility-contrast {
  &,
  .subpage-layout-side-wrapper,
  .public-header,
  .ant-menu,
  .public-layout-nav,
  .subpage-layout-img-content,
  .landing-page-banner,
  .public-layout-nav-menu,
  .public-layout-nav-menu-footer,
  .footer,
  .img-content,
  #nowy-klient,
  #public-layout {
    background-color: #000 !important;
    background: #000 !important;
  }
  & *:not(.ant-select-item-option-content) {
    color: #00f338 !important ;
    fill: #00f338 !important ;
  }
  .ant-select-item-option-content {
    color: #000 !important;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;