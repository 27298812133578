.not-found-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .not-found-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 20vh;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    .not-found-title {
      font-size: 120px;
      margin-bottom: 0;
      color: #fff;
    }
  }

  .not-found-link {
    text-decoration: underline;
    font-weight: 600;
  }
  .not-found-bgc {
    position: absolute;
    height: 50vh;
    top: 0;
    left: 0;
    right: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: blur(1.3px);
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .not-found-content {
    position: absolute;
    top: 55vh;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .not-found-subtitle {
      margin-bottom: 32px;
      max-width: 600px;
      text-align: center;
    }
  }
  .not-found-link-wrapper {
    display: flex;
    gap: 8px;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;