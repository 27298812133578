.general-information {
  padding: 24px;
  h2 {
    font-weight: 800;
    margin: 0;
  }
  p.text-strong {
    margin-bottom: 12px;
  }
  p {
    margin-bottom: 4px;
  }

  @media (min-width: 992px) {
    h2 {
      font-size: 32px;
    }
  }
  @media (min-width: 1200px) {
    padding: 64px 24px;
  }
  @media (min-width: 1400px) {
    padding: 64px;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;