@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.landing-page-banner {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  overflow: hidden;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  // opacity: 0;
  // animation: show 1s forwards;
  .ant-carousel {
    max-width: 90%;
  }
  //   &-arrow {
  //     color: #fff;
  //     font-weight: 600;
  //   }
  .slick-list {
    padding-bottom: 32px;
  }
  &-link {
    background-color: @primary-color;
    color: #fff;
    padding: 5px 15px;
    border-radius: 3px;
    &:hover {
      color: #ddd;
    }
  }
  &-slide {
    text-align: center;
    h1 {
      color: #fff;
      font-weight: 600;
      font-size: 32px;
      margin: 0;
    }
  }
  &-subheader {
    font-size: 16px;
    color: #f8f8f8;
    font-weight: 400;
    margin-bottom: 0;
    span {
      font-weight: 600;
    }
  }
  .slick-arrow {
    background-color: transparent;
    z-index: 1;
    color: #fff;
    height: 50px;
    width: 50px;
    margin-top: -35px;
    svg {
      font-size: 40px;
    }
    &:hover {
      transform: scale(1.1);
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
  }
  @media (min-width: 576px) {
    &-slide {
      h1 {
        font-size: 40px;
      }
    }
  }
  @media (min-width: 768px) {
    &-subheader {
      font-size: 30px;
    }
    &-slide {
      h1 {
        font-size: 50px;
      }
    }
    &-button {
      font-size: 16px;

      height: 40px;
    }
  }
  @media (min-width: 992px) {
    &-slide {
      h1 {
        font-size: 60px;
      }
    }
    &-link {
      padding: 7px 20px;
    }
  }
  @media (min-width: 1200px) {
    padding: 64px;

    .ant-carousel {
      max-width: 800px;
    }

    .slick-list {
      padding-bottom: 64px;
    }
    &-slide {
      h1 {
        line-height: 80px;
        font-size: 70px;
      }
    }
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;