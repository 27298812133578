.public-layout-nav {
  background-color: #fff;
  width: 100%;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  &-site-wrapper {
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-logo-wrapper {
    z-index: 2;
    max-width: 120px;
    min-width: 100px;
    img {
      width: 100%;
    }
  }
  &-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;

    display: none;
    flex-direction: column;
    justify-content: space-between;
    .ant-menu-root {
      z-index: 0;
      margin-top: 92px;
      max-height: calc(100vh - 92px - 60px);
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      .ant-menu-title-content {
        text-transform: capitalize;
        font-weight: 600;
      }
    }

    footer {
      z-index: 1;
      font-size: 12px;
      padding: 18px 12px;
      color: #fff;
      background-color: @primary-color;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
      }
    }
  }
  &-menu-active {
    display: flex;
  }
  @media (min-width: 992px) {
    & {
      height: 73px;
    }
    &-site-wrapper {
      padding: 12px 24px;
      max-width: 1400px;
      margin: 0 auto;
    }
    &-menu {
      display: block;
      position: static;
      height: auto;
      width: auto;
      flex-grow: 1;
      min-width: 460px;
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .footer-wrapper {
        display: none;
      }
      // footer {
      //   display: none;
      // }
      .ant-menu-root {
        margin: 0;
      }
      .ant-menu-horizontal {
        border: 0;
        overflow-y: initial;
        overflow-x: initial;
      }
    }
    &-toggle {
      display: none;
    }
    &-logo-wrapper {
      max-width: 140px;
    }
  }
  @media (min-width: 1200px) {
    &-logo-wrapper {
      margin-right: 117px;
    }
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;