@import "antd/dist/antd.less";
.localization-select {
  .ant-select-selector {
    background-color: @primary-color !important;
  }

  .ant-select-arrow,
  .ant-select-selector {
    color: #fff;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;