@import "antd/dist/antd.less";
.footer {
  width: 100%;
  background-color: #f7f7f7;
  padding: @global-padding;
  // border-top: 1px solid rgba(0, 0, 0, 0.05);
  p,
  .collapse-list-link {
    color: rgba(39, 39, 39, 0.8);
  }

  strong {
    color: rgba(0, 0, 0, 0.85);
  }
  &-collapse {
    background-color: transparent;
    .ant-collapse-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    &-list {
      list-style: none;
      padding: 0;
    }
  }

  .collapse-list-el {
    margin-bottom: 6px;
    p {
      margin: 0;
    }
  }
  .collapse-list-el-separate {
    margin: 12px 0;
  }
  .collapse-list-link:hover {
    text-decoration: underline;
    color: @primary-color;
  }
  &-subsides {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    margin-top: 24px;
    &-img-wrapper {
      width: 100%;
      margin-bottom: 24px;
      img {
        width: 100%;
        max-width: 670px;
        display: block;
        margin: 0 auto;
      }
    }
    &-content {
      max-width: 900px;
      margin: 0 auto;
      text-align: center;
      p {
        margin: 0;
      }
      & > p {
        display: inline-block;
        margin-bottom: 18px;
      }
    }
  }
  &-copyright {
    text-align: center;
    margin-top: 24px;
    p {
      color: rgba(39, 39, 39, 0.8);
      margin: 0;
    }

    .realizacja{
      a{
        color: #82C222;
        font-weight: 500;
      }
    }
  }
  .ant-divider-horizontal {
    max-width: 1200px !important;
    min-width: auto;
    margin: 24px auto;
  }
  &-grid {
    max-width: 1200px;
    margin: 0 auto;
    padding: 64px 0;
    display: grid;
    grid-template-columns: 400px 400px;
    row-gap: 12px;
    column-gap: 24px;
    justify-content: center;
    @media (min-width: 1200px) {
      grid-template-columns: 300px 400px 400px;
      justify-content: space-between;
      column-gap: 32px;
      article:nth-of-type(4) {
        grid-column-start: 2;
      }
    }
    &-article-title {
      font-size: 20px !important;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 25px;
        width: 3px;
        left: -7px;
        background-color: @secondary-color;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;