.rodo-page {
  padding: 24px;
  h2 {
    font-weight: 800;
  }
  &-sign {
    display: block;
    width: 100%;
    margin-top: 32px;
    font-style: italic;
    text-align: right;
    text-decoration: underline;
  }
  @media (min-width: 992px) {
    h2 {
      font-size: 32px;
    }
  }
  @media (min-width: 1200px) {
    padding: 64px 24px;
    &-details-wrapper {
      margin-top: 32px;
    }
  }

  @media (min-width: 1400px) {
    padding: 64px;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;