.auth-layout {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    width: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 3px;
  }
  .ant-form-item {
    margin-bottom: 6px;
    flex-direction: column;
    align-items: flex-start;
    .ant-form-item-control {
      width: 100%;
    }
    label {
      height: 24px;
    }
  }
}
.login-form-button {
  width: 100%;
}
.login-form-forgot {
  float: right;
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;