.new-client {
  padding: 32px 24px;
  background-color: #f5f5f5;
  h2 {
    font-weight: 800;
  }
  &-form-wrapper {
    padding: 24px 0;
    .ant-form-item {
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      .ant-form-item-label {
        display: inline;
        text-align: left;
      }
    }
  }

  @media (min-width: 768px) {
    &-form-wrapper-half {
      width: 100%;
      display: flex;
      gap: 12px;
      .ant-form-item {
        flex-grow: 1;
        width: 50%;
      }
    }
  }
  @media (min-width: 992px) {
    padding: 48px 24px;
    margin-bottom: 48px;
    h2 {
      font-size: 24px;
    }
  }

  @media (min-width: 1400px) {
    padding: 64px;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;