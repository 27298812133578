.news-form {
  &-half {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    & > div {
      flex: 1;
      min-width: 150px;
    }
  }
  .ant-form-item {
    margin-bottom: 6px;
    flex-direction: column;
    align-items: flex-start;
    .ant-form-item-control {
      width: 100%;
    }
    label {
      height: 24px;
    }
  }
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .editor-body {
    min-height: 150px;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
  }
}
.ant-upload {
  overflow: hidden;
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;