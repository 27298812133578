.subpage-layout {
  min-height: 100vh;

  &-img-wrapper {
    width: 100%;
    height: 45vh;

    position: relative;
    img {
      filter: blur(2px);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  &-body {
    position: relative;
    &-content {
      margin: 0 auto;
      max-width: 1260px;
    }
  }
  &-img-content {
    padding: 12px 24px;
    position: absolute;
    z-index: 1;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 1200px;
    text-align: center;
    h1 {
      font-weight: 600;
      margin: 0;
      font-size: 32px;
      color: #fff;
    }
    p {
      color: #f5f5f5;
      max-width: 450px;
      margin: 0 auto;
    }
  }
  @media (min-width: 576px) {
    &-img-content {
      h1 {
        font-size: 40px;
      }
    }
  }
  @media (min-width: 768px) {
    &-img-content {
      h1 {
        font-size: 50px;
      }
    }
  }
  @media (min-width: 1200px) {
    &-img-content {
      &::before,
      &::after {
        position: absolute;
        z-index: 2;
        content: "";
        top: 50%;
        transform: translateY(-50%);
        width: 150px;
        height: 1px;
        background-color: #fff;
      }
      &::before {
        left: 180px;
      }
      &::after {
        right: 180px;
      }
    }
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;