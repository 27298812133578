.contact-branches {
  padding: 0 24px;
  &-branch {
    margin-top: 12px;
    h3 {
      font-size: 14px;
      border-radius: 2px;
      padding-left: 6px;
      margin-bottom: 14px;
      color: #33305e;
      background-color: #8791eb;
    }

    ul {
      padding-left: 23px;
    }
    &-wrapper {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  &-map-wrapper {
    width: 100%;
    margin: 24px 0 48px 0;
    h4 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    p {
      margin-bottom: 24px;
    }
    img {
      width: 100%;
      max-height: 40vh;
      object-fit: cover;
    }
  }
  @media (min-width: 768px) {
    &-branch {
      &-wrapper {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media (min-width: 1024px) {
    &-branch {
      &-wrapper {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
  @media (min-width: 1400px) {
    padding: 0 64px;
    margin-bottom: 64px;
  }
}
.adress {
  // padding: 8px;
  border-radius: 3px;
  margin-bottom: 18px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  // background-color: rgba(0, 0, 0, 0.02);
  p {
    margin: 0;
    font-size: 14px;
  }
  h2 {
    font-size: 14px;
    margin: 0;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;