*,
body,
html {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.text-strong {
  font-weight: 600;
}
body.user-is-tabbing *:focus {
  outline: 3px solid red !important;
}
body.user-is-tabbing a:focus {
  background-color: #fdb827;
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;