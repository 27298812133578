.admin-layout {
  width: 100%;
  &-header {
    width: 100%;
    padding: 12px 24px;
    background-color: @primary-color;
    & > div {
      max-width: 1500px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &-content {
    width: 100%;
    max-width: 1300px;
    padding: 24px;
    margin: 0 auto;
  }
  .admin-logo {
    color: #fff;
  }
}

@primary-color: #393666;@secondary-color: #FBBB00;@global-padding: 24px;@border-radius-base: 5px;